.taskbar {
  &.MuiPaper-root {
    background: #cccccc5b;
    filter: blur(10);
    height: 50px;
    position: absolute;
    bottom: 0px;
    color:#fff;
    min-width: 100%;
    overflow: hidden;
    max-width: 100vw;
    // padding:0px 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
